import { FC, useEffect, useRef, useState } from "react";
import { Grid, Box, CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import TouchAppIcon from "@mui/icons-material/TouchApp";

import CustomerHistoryTableRow from "./customer-history-table-row";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { getCustomerBillHistoryAction } from "../../../../redux/actions/customerAction";
import InvoiceHistoryModal from "../../../pos/invoice-history-modal/invoice-history-modal";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { ICustomerBill, ICustomerBillsResponse } from "../../../../typings";
import {
  addSession,
  getBillByIdAction,
  updateCartSessionItem,
} from "../../../../redux/actions/cartAction";
import { Colors } from "../../../../configs";
import { ICartSession } from "../../../../typings/interfaces/pos/cart-session";
import { CartSliceAction } from "../../../../redux/slice/cartSlice";
import { useSnackbarContext } from "../../../../providers/SnackbarProvider";
import {
  BillFilterDateType,
  BillPaymentStatus,
  BillPickupStatus,
} from "../../../../typings/enum/paymentTypes";

export interface ICustomerHistoryTableProps {
  contentHeight?: string;
  paymentFilterStatus: BillPaymentStatus | null;
  filterDateType: BillFilterDateType;
  startDate: string | undefined;
  endDate: string | undefined;
  pickingFilterStatus?: BillPickupStatus | null;
  searchQuery?: string;
}

const CustomerHistoryTable: FC<ICustomerHistoryTableProps> = ({
  contentHeight = "39.07rem",
  paymentFilterStatus,
  filterDateType,
  startDate,
  endDate,
  pickingFilterStatus,
  searchQuery,
}) => {
  const params = useParams();
  const customerId = params.id;
  const queryParams = new URLSearchParams(window.location.search);
  const billId = queryParams.get("billId");
  const dispatch = useAppDispatch();
  const { bills, billsLoading } = useAppSelector((state) => state.customer);
  const { refreshCustomerHistory } = useAppSelector((state) => state.cart);
  const [clickedRow, setClickedRow] = useState<ICustomerBill | null>(null);
  const snackbar = useSnackbarContext();

  const [openModal, setOpenModal] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<ICustomerBill | null>(
    null
  );
  const [localItems, setLocalItems] = useState<ICustomerBill[]>([]);
  const [loadingNextPage, setLoadingNextPage] = useState(false);

  const tableRef = useRef<HTMLDivElement>(null);

  const handleOnClickRow = (data: ICustomerBill) => {
    setClickedRow(data);
    dispatch(CartSliceAction.clearSession());
    if (data.billString) {
      dispatch(addSession(JSON.parse(data.billString)));
    } else {
      snackbar.showSnackbar("cart not found for the bill", "error");
    }
  };

  const loadNextPage = async () => {
    setLoadingNextPage(true);
    bills?.pageable.pageNumber !== undefined &&
      getCustomerBillList(bills.pageable.pageNumber + 1);
  };

  useEffect(() => {
    if (billId) {
      // setClickedRow({
      //   billId: billId,
      //   id: Number(billId),
      // });
      setClickedRow(
        bills?.content?.find((bill) => bill.billId === billId) || null
      );
    }
  }, [billId]);

  useEffect(() => {
    if (clickedRow?.id) {
      dispatch(getBillByIdAction({ billId: String(clickedRow?.id) }));
    }
  }, [clickedRow]);

  useEffect(() => {
    getCustomerBillList();
  }, [
    customerId,
    paymentFilterStatus,
    pickingFilterStatus,
    filterDateType,
    startDate,
    endDate,
  ]);

  const refreshAlreadyOpenedPages = async () => {
    if (refreshCustomerHistory) {
      const openedPage = bills?.pageable.pageNumber ?? 0;
      for (let i = 0; i <= openedPage; i++) {
        await getCustomerBillList(i);
      }
      dispatch(CartSliceAction.setRefreshCustomerHistory(false));
    }
  };

  useEffect(() => {
    refreshAlreadyOpenedPages();
  }, [refreshCustomerHistory]);

  const getCustomerBillList = async (page: number = 0) => {
    if (customerId) {
      await dispatch(CartSliceAction.setCartHistorySelectedRows([]));
      dispatch(CartSliceAction.clearSession());
      setClickedRow(null);
      if (page > 0) {
        setLocalItems([...(bills?.content || [])]);
      }
      const res = await dispatch(
        getCustomerBillHistoryAction({
          customerId: Number(customerId),
          paymentFilterStatus: paymentFilterStatus ?? undefined,
          pickingFilterStatus: pickingFilterStatus ?? undefined,
          filterDateType: filterDateType,
          startDate: startDate
            ? new Date(startDate).toISOString().split("T")[0]
            : undefined,
          endDate: endDate
            ? new Date(endDate).toISOString().split("T")[0]
            : undefined,
          page: page,
          size: 10,
        })
      );
      if (page > 0) {
        if ((res.payload as ICustomerBillsResponse).content) {
          const newItems = [
            ...localItems,
            ...(res.payload as ICustomerBillsResponse).content,
          ];

          console.log("newItems length", newItems.length);
          console.log("existing length", localItems.length);
          console.log("bills length", bills?.content.length);

          setLocalItems(newItems);
          setTimeout(() => {
            if (tableRef.current) {
              tableRef.current.scrollTop = tableRef.current.scrollHeight;
            }
          }, 10);
        }
      } else {
        setLocalItems((res.payload as ICustomerBillsResponse)?.content || []);
      }
      setLoadingNextPage(false);
    }
  };
  const styles = {
    tableHeadingCell: {
      // borderRadius: "0.71rem",
      borderRadius: "0",
      background: Colors.SECONDARY,
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: "0.5rem 0.71rem",
      boxSizing: "border-box",
      color: "white",
      height: "2.92857142857rem",
    },
  };

  const handleViewClick = (data: ICustomerBill) => {
    setSelectedInvoice(data);
    setOpenModal(true);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        background: Colors.BACKGROUND_GRAY,
        borderRadius: "0",
        paddingLeft: "0.68rem",
        gap: "1.14rem",
        height: "28.42rem",
      }}
    >
      <Box sx={{ flex: 4, minWidth: "100%", maxWidth: "100% !important" }}>
        <Grid container sx={{ gap: "0.21rem", height: "100%" }}>
          <Grid item xs={12}>
            <Box style={{ position: "sticky", top: 0 }}>
              <Grid container sx={{ gap: "0.15rem", flexWrap: "nowrap" }}>
                <Grid
                  item
                  xs={1}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "2.92857142857rem",
                    maxWidth: "2.92857142857rem !important",
                    borderTopLeftRadius: "0.71rem",
                  }}
                >
                  &nbsp;
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: { lg: "6.4rem", md: "6.4rem" },
                    maxWidth: {
                      lg: "6.4rem !important",
                      md: "6.4rem !important",
                    },
                    fontSize: "1rem",
                  }}
                >
                  Invoice
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "7.826rem",
                    maxWidth: "7.826rem !important",
                    fontSize: "1rem",
                  }}
                >
                  Date In
                </Grid>
                {/* cashier column */}
                <Grid
                  item
                  xs={2.5}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: { lg: "7rem", md: "7rem" },
                    maxWidth: {
                      lg: "7rem !important",
                      md: "7rem !important",
                    },
                    fontSize: "1rem",
                  }}
                >
                  Cashier
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "7.826rem",
                    maxWidth: "7.826rem !important",
                    fontSize: "1rem",
                  }}
                >
                  Due date
                </Grid>
                <Grid
                  item
                  xs={2.5}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "5.606rem",
                    maxWidth: "5.606rem !important",
                    fontSize: "1rem",
                  }}
                >
                  Pieces
                </Grid>
                <Grid
                  item
                  xs={2.5}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "4.606rem",
                    maxWidth: "4.606rem !important",
                    fontSize: "1rem",
                  }}
                >
                  Rack
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "5.606rem",
                    maxWidth: "5.606rem !important",
                    fontSize: "1rem",
                  }}
                >
                  Balance
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "7.826rem",
                    maxWidth: "7.826rem !important",
                    fontSize: "1rem",
                  }}
                >
                  Pay Date
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "7.826rem",
                    maxWidth: "7.826rem !important",
                    fontSize: "1rem",
                  }}
                >
                  Pickup Date
                </Grid>
                <Grid
                  item
                  xs={1}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "5.246rem",
                    maxWidth: "5.246rem !important",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderTopRightRadius: "0.71rem",
                  }}
                >
                  <TouchAppIcon sx={{ color: "white" }} />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ height: contentHeight }}>
            <Box
              sx={{
                height: contentHeight,
                overflowY: "auto",
                overflowX: "hidden",
                "&::-webkit-scrollbar": {
                  width: { lg: "0.5rem", md: "0.3rem", sm: "0rem" },
                  marginRight: "-0.5rem",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#ADADAD",
                  borderRadius: "0rem",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "transparent",
                  borderRadius: "0rem",
                },
              }}
              ref={tableRef}
            >
              {/* Item List */}
              {((!billsLoading && !loadingNextPage) ||
                (billsLoading && loadingNextPage)) &&
                [...localItems]
                  .filter((item) => {
                    return (
                      !searchQuery ||
                      item.billId
                        ?.toLowerCase()
                        .includes(searchQuery?.toLowerCase())
                    );
                  })
                  .map((item, index) => (
                    <CustomerHistoryTableRow
                      key={index}
                      isSelected={clickedRow?.id === item.id}
                      data={item}
                      firstRow={index === 0}
                      lastRow={index === (bills?.content || []).length - 1}
                      onClick={handleOnClickRow}
                      onViewClick={handleViewClick}
                    />
                  ))}

              {/* Not Found */}
              {!billsLoading &&
                [...(bills?.content || [])].filter((item) => {
                  return (
                    !searchQuery ||
                    item.billId
                      ?.toLowerCase()
                      .includes(searchQuery?.toLowerCase())
                  );
                }).length === 0 && (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      py: "1rem",
                      color: Colors.SECONDARY,
                    }}
                  >
                    No bills found
                  </Box>
                )}

              {/* Loading */}
              {billsLoading && (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress
                    sx={{
                      mx: "auto",
                      mt: "7rem",
                    }}
                  />
                </Box>
              )}

              {/* Load More */}
              {!billsLoading &&
                !loadingNextPage &&
                [...localItems].length > 0 &&
                (bills?.totalPages ?? 1) - 1 >
                  (bills?.pageable.pageNumber ?? 0) && (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      py: "1rem",
                      color: Colors.SECONDARY,
                      cursor: "pointer",
                      mt: "1rem",
                    }}
                    onClick={loadNextPage}
                  >
                    <KeyboardDoubleArrowDownIcon /> Load More
                  </Box>
                )}
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Render InvoiceHistoryModal */}
      {selectedInvoice && (
        <InvoiceHistoryModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          // invoiceData={selectedInvoice}
          selectedId={selectedInvoice.id}
          billId={selectedInvoice.billId ?? ""}
        />
      )}
    </Box>
  );
};

export default CustomerHistoryTable;
